<template>
  <div class="">
    <b-card
      class="mx-5 border-top-primary"
    >
      <b-row>
        <b-col
          lg="12"
          md="12"
          sm="12"
          class="d-flex justify-content-start mb-1"
        >
          <b-form-input
            v-model="formulario[0].nombre"
            size="lg"
            class="font-weight-bold"
            style="background-color:#EFFBF8; color:#0B614B; border-top: 0px; border-bottom: 2px solid #048975; border-left: 0px; border-right: 0px; border-radius:0px "
            @update="actualizarFormularioSegunTiempo()"
          />
        </b-col>

        <b-col cols="12">
          <b-form-textarea
            v-model="formulario[0].descripcion"
            placeholder="Sin descripción ..."
            rows="2"
            @update="actualizarFormularioSegunTiempo()"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-row
      v-for="(item, indexSeccion) in formulario[0].secciones"
      :key="indexSeccion"
    >
      <b-col cols="12">
        <new-seccion
          :index-seccion="indexSeccion"
        />
      </b-col>
    </b-row>
    <b-overlay
      :show="showOverlay"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BCard, BFormTextarea, BOverlay,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NewSeccion from './newSeccion.vue'
import newFormularioStoreModule from './newFormularioStoreModule'

export default {
  components: {
    BCard,
    BFormTextarea,
    BRow,
    BCol,
    BFormInput,
    BOverlay,
    // extern
    NewSeccion,
  },
  directives: {
    Ripple,
  },
  props: {
    idFormulario: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timeout: null,
    }
  },
  computed: {
    ...mapState('newFormularioModule', ['formulario', 'showOverlay']),
  },
  created() {
    store.dispatch('newFormularioModule/getFormulario', this.idFormulario).then(res => {
      this.formulario.splice(0, 1)
      this.formulario.push(res.data)
    })
  },

  methods: {
    actualizarFormularioSegunTiempo() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.updateformulario()
      }, 1800)
    },
    async updateformulario() {
      store.state.newFormularioModule.showOverlay = true
      await store.dispatch('newFormularioModule/updateFormulario', this.formulario).then(res => {
        this.$set(this.formulario, 0, res.data)
      })
      store.state.newFormularioModule.showOverlay = false
      this.$toast({
        component: ToastificationContent,
        position: 'b-toaster-bottom-center',
        props: {
          title: '¡Formulario actualizado!',
          icon: 'CheckCircleIcon',
          variant: 'info',
        },
      })
    },

  },

  setup() {
    const NEW_FORMULARIO_STORE_MODULE_NAME = 'newFormularioModule'

    // Register module
    if (!store.hasModule(NEW_FORMULARIO_STORE_MODULE_NAME)) store.registerModule(NEW_FORMULARIO_STORE_MODULE_NAME, newFormularioStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(NEW_FORMULARIO_STORE_MODULE_NAME)) store.unregisterModule(NEW_FORMULARIO_STORE_MODULE_NAME)
    })

    return {
      newFormularioStoreModule,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
