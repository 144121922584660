<template>
  <div class="d-flex mx-3 mt-1 p-0 my-0 ">
    <b-card
      header-tag="header"
      footer-tag="footer"
      footer-bg-variant="light"
      class="ml-3 mr-1 flex-grow-1 my-0 p-0"
      style="border-left: 5px solid #048975;"
    >

      <div class="">

        <b-row>
          <b-col
            xl="7"
            lg="7"
            md="7"
            sm="12"
            cols="7"
            class="mb-0"
          >
            <small class="text-muted">Pregunta:</small>

            <b-form-input
              :ref="'ref'+formulario[0].secciones[indexSeccion].preguntas[indexPregunta].id"
              v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].pregunta"
              placeholder="Pregunta"
              size="lg"
              class="font-weight-bold"
              style="background-color:#EFFBF8; color:#0B614B; border-top: 0px; border-bottom: 2px solid #048975; border-left: 0px; border-right: 0px; border-radius:0px "
              @update="actualizarFormularioSegunTiempo()"
            />
          </b-col>
          <b-col
            xl="5"
            lg="5"
            md="5"
            sm="12"
          >
            <small class="text-muted">Tipo pregunta:</small>
            <b-form-select
              v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo"
              size="lg"
              :options="optionsTipoPregunta"
              @change="cambiarPregunta(formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo)"
            />
          </b-col>
          <b-col
            v-if="conDescripcion"
            cols="12"
            class="mt-1"
          >
            <small class="text-muted">Descripción:</small>
            <b-form-input
              v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].descripcion"
              style="border-top: 0px; border-bottom: 2px solid #048975; border-left: 0px; border-right: 0px; border-radius:0px; "
              @update="actualizarFormularioSegunTiempo()"
            />
          </b-col>
        </b-row>
        <!-- BODY PREGUNTA -->
        <b-row class="">
          <b-col
            v-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'respuesta_corta'"
            cols="12"
          >
            <label
              class="mt-2 px-3 py-1 border rounded"
              style="font-size:14px"
            ><em>Texto de respuesta breve</em></label>
          </b-col>
          <b-col
            v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'parrafo'"
            cols="12"
          >
            <label
              class="mt-2 px-3 py-1 border rounded"
              style="font-size:14px"
            ><em>Texto de respuesta larga</em></label>
          </b-col>
          <b-col
            v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'varias_opciones' || formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'casillas' || formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'desplegable'"
            cols="12"
          >
            <b-row class="mt-1">
              <b-col cols="12">
                <draggable
                  v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles"
                  class="list-group list-group-flush"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                  >
                    <b-list-group-item
                      v-for="(fila,index) in formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles"
                      :key="index"
                      tag="li"
                      class="border-bottom borderHover"
                    >
                      <div
                        class="d-flex justify-content-start align-items-center"
                      >
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="24"
                          class="draggable-task-handle d-inline mr-2 cursor-move text-primary"
                        />

                        <feather-icon
                          v-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'varias_opciones'"
                          icon="CircleIcon"
                          size="24"
                          class="mr-1 text-secondary"
                        />
                        <feather-icon
                          v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'casillas'"
                          icon="SquareIcon"
                          size="24"
                          class="mr-1 text-secondary"
                        />
                        <feather-icon
                          v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'desplegable'"
                          icon="ChevronRightIcon"
                          size="24"
                          class="mr-1 text-secondary"
                        />
                        <b-form-input
                          v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles[index].respuesta"
                          size="sm"
                          @update="actualizarFormularioSegunTiempo()"
                        />
                        <span>
                          <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-danger"
                            class="btn-icon rounded-circle ml-1"
                            @click="eliminarOpcionRespuesta(index)"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="22"
                            />
                          </b-button>
                        </span>
                      </div>
                    </b-list-group-item>
                  </transition-group>
                </draggable>
              </b-col>

            </b-row>
            <b-button
              variant="outline-secondary"
              class="mt-1 ml-2"
              size="sm"
              @click="agregarOpcionFila(formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles.length)"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Nueva opcion
            </b-button>

          </b-col>

          <b-col
            v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'archivo'"
            cols="12"
          >
            <span class="border rounded px-3 py-1">
              <label class="text-secondary mr-2 mt-2">Cargar archivo</label>
              <feather-icon icon="UploadIcon" />
            </span>

          </b-col>
          <b-col
            v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'fecha'"
            cols="12"
          >
            <span class="border rounded px-3 py-1">
              <label class="text-secondary mr-2 mt-2">Fecha</label>
              <feather-icon icon="CalendarIcon" />
            </span>
          </b-col>
          <b-col
            v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'hora'"
            cols="12"
          >
            <span class="border rounded px-3 py-1">
              <label class="text-secondary mr-2 mt-2">Hora</label>
              <feather-icon icon="ClockIcon" />
            </span>
          </b-col>
          <b-col
            v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'compuesta'"
            cols="12"
          >
            <b-row class="mt-1">
              <b-col
                v-for="(fila,index) in formulario[0].secciones[indexSeccion].preguntas[indexPregunta].compuesta"
                :key="'ref2'+index"
                cols="12"
                style="margin-bottom: 8px;"
              >
                <new-pregunta-compuesta
                  :index-seccion="indexSeccion"
                  :index-pregunta="indexPregunta"
                  :index-pregunta-compuesta="index"
                />
              </b-col>
            </b-row>
            <b-button
              variant="outline-warning"
              class="mt-1 ml-2"
              size="sm"
              @click="agregarFilaPreguntaCompuesta()"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Adicionar pregunta
            </b-button>
          </b-col>
          <b-col
            v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'mixto_varias_opciones' || formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'mixto_casillas' || formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'mixto_desplegable'"
            cols="12"
          >
            <b-row class="mt-1">
              <b-col
                cols="12"
                class="py-1"
                style="margin-bottom: 8px;"
              >
                <draggable
                  v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles"
                  class="list-group list-group-flush"
                >
                  <transition-group
                    type="transition"
                    name="flip-list"
                  >
                    <b-list-group-item
                      v-for="(fila,index) in formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles"
                      :key="index"
                      tag="li"
                      class="border-bottom borderHover"
                    >
                      <div class="d-flex justify-content-start align-items-center">

                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="24"
                          class="draggable-task-handle d-inline mr-2 cursor-move text-primary"
                        />
                        <feather-icon
                          v-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'mixto_varias_opciones'"
                          icon="CircleIcon"
                          size="24"
                          class="mr-1 text-secondary"
                        />
                        <feather-icon
                          v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'mixto_casillas'"
                          icon="SquareIcon"
                          size="24"
                          class="mr-1 text-secondary"
                        />
                        <feather-icon
                          v-else-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].tipo === 'mixto_desplegable'"
                          icon="ChevronRightIcon"
                          size="24"
                          class="mr-1 text-secondary"
                        />
                        <b-form-input
                          v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles[index].respuesta"
                          size="sm"
                          @update="actualizarFormularioSegunTiempo()"
                        />
                        <span>
                          <b-button
                            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                            variant="flat-danger"
                            class="btn-icon rounded-circle ml-1"
                            @click="eliminarOpcionRespuesta(index)"
                          >
                            <feather-icon
                              icon="XIcon"
                              size="22"
                            />
                          </b-button>
                        </span>
                      </div>

                      <b-row>

                        <b-col
                          xl="3"
                          lg="3"
                          md="3"
                          sm="12"
                        >
                          <b-form-checkbox
                            v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles[index].mixto"
                            unchecked-value="0"
                            value="1"
                            switch
                            class="ml-3 mr-2 p-0"
                            @change="limpiarMixtoDetalle(index)"
                          >
                            <span
                              class="p-0"
                              style="font-size:14px"
                            >¿Mixto?</span>
                          </b-form-checkbox>
                        </b-col>
                        <b-col
                          xl="9"
                          lg="9"
                          md="9"
                          sm="12"
                        >
                          <span
                            v-if="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles[index].mixto"
                            class="d-flex justify-content-start align-items-center"
                          >
                            <label
                              class="mx-1"
                              style="font-size:14px"
                            >Detalle mixto: </label>
                            <span>
                              <b-form-input
                                v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles[index].mixto_detalles"
                                size="sm"
                                style="width:250px"
                                @update="actualizarFormularioSegunTiempo()"
                              />
                            </span>
                          </span>
                        </b-col>
                      </b-row>
                    </b-list-group-item>
                  </transition-group>
                </draggable>
              </b-col>
            </b-row>
            <b-button
              variant="outline-secondary"
              class="mt-1 ml-2"
              size="sm"
              @click="agregarOpcionFila(formulario[0].secciones[indexSeccion].preguntas[indexPregunta].detalles.length)"
            >
              <feather-icon
                icon="PlusIcon"
              />
              Nueva opcion
            </b-button>

          </b-col>

          <b-col
            v-else
            cols="12"
          >
            <label class="py-2"><em>NO HAY PREGUNTA SELECCIONADA</em></label>
          </b-col>
        </b-row>
      </div>

      <template
        #footer
      >
        <div class="d-flex justify-content-end align-items-center">
          <span>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-secondary"
              class="btn-icon rounded-circle"
              @click="duplicarPregunta"
            >
              <feather-icon
                icon="CopyIcon"
                size="24"
              />
            </b-button>
          </span>

          <span>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-secondary"
              class="btn-icon rounded-circle mr-1"
              @click="eliminarPregunta"
            >
              <feather-icon
                icon="Trash2Icon"
                size="24"
              />
            </b-button>

          </span>

          <span
            style="padding-top:10px"
            class="m-0 border-left pl-2"
          >
            <b-form-checkbox
              v-model="formulario[0].secciones[indexSeccion].preguntas[indexPregunta].requerido"
              unchecked-value="0"
              value="1"
              class="m-0 p-0"
              switch
              @change="updateformulario()"
            >Obligatoria</b-form-checkbox>
          </span>

          <span>
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
              class="m-0 p-0"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="20"
                  class="align-middle text-body m-0 p-0"
                />
              </template>
              <b-dropdown-item
                @click="() => conDescripcion = !conDescripcion"
              >
                <feather-icon
                  v-if="conDescripcion"
                  icon="CheckIcon"
                />
                <span class="align-middle ml-50">Descripción</span>
              </b-dropdown-item>

            </b-dropdown>
          </span>
        </div>

      </template>
    </b-card>
    <div v-show="true">
      <div
        class="d-flex herramientas flex-column justify-content-between py-75 py-1"
      >
        <b-button
          v-ripple.400="'rgba(40, 199, 111, 0.15)'"
          v-b-tooltip.hover.left.v-primary
          variant="flat-primary"
          class="btn-icon rounded-circle"
          title="Agregar pregunta"
          @click="addPregunta(indexSeccion, indexPregunta)"
        >
          <feather-icon
            size="26"
            icon="PlusCircleIcon"
            class="cursor-pointer"
          />
        </b-button>

      </div>
    </div>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BFormSelect, BFormCheckbox, BDropdown, BDropdownItem,
  BButton, BListGroupItem, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { mapState } from 'vuex'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import newPreguntaCompuesta from './newPreguntaCompuesta.vue'

export default {
  components: {
    draggable,
    BListGroupItem,
    BCard,
    BFormSelect,
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BFormInput,
    newPreguntaCompuesta,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {

    indexSeccion: {
      type: Number,
      default: 0,
    },
    indexPregunta: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timeout: null,
      opcionFilas: [{ respuesta: '' }],
      conDescripcion: false,
      optionsTipoPregunta: [
        {
          label: 'SIMPLES',
          options: [
            { value: 'respuesta_corta', text: 'Respuesta corta', icon: 'AlertTriangleIcon' },
            { value: 'parrafo', text: 'Parrafo' },
            { value: 'varias_opciones', text: 'Varias opciones' },
            { value: 'casillas', text: 'Casillas' },
            { value: 'desplegable', text: 'Desplegable' },
            { value: 'archivo', text: 'Archivo' },
            { value: 'fecha', text: 'Fecha' },
            { value: 'hora', text: 'Hora' },
            // { value: 'compuesta', text: 'Compuesta' },
          ],
        },
        {
          label: 'MIXTOS',
          options: [
            { value: 'mixto_varias_opciones', text: 'Mixto varias opciones' },
            { value: 'mixto_casillas', text: 'Mixto casillas' },
            { value: 'mixto_desplegable', text: 'Mixto deplegable' },
          ],
        },

      ],
    }
  },
  computed: {
    ...mapState('newFormularioModule', ['formulario', 'showOverlay']),
  },

  methods: {
    actualizarFormularioSegunTiempo() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.updateformulario()
      }, 2000)
    },
    agregarFilaPreguntaCompuesta() {
      this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta].compuesta.push({ pregunta: '' })
      this.updateformulario()
    },
    agregarOpcionFila(index) {
      this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta].detalles.push({ respuesta: '' })
      this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta].detalles[index], 'respuesta', `Opción ${index + 1}`)
      this.actualizarFormularioSegunTiempo()
    },
    eliminarOpcionRespuesta(index) {
      this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta].detalles.splice(index, 1)
      this.updateformulario()
    },
    eliminarPregunta() {
      this.$swal({
        title: '¿Esta seguro de eliminar la pregunta?',
        text: 'Al eliminar la pregunta se eliminaran las respuestas que contiene.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, ¡Eliminar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.formulario[0].secciones[this.indexSeccion].preguntas.splice(this.indexPregunta, 1)
          this.updateformulario()
        }
      })
    },
    duplicarPregunta() {
      const COPIA = JSON.parse(JSON.stringify(this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta]))
      delete COPIA.id
      delete COPIA.random
      delete COPIA.posicion
      if (COPIA.detalles) {
        COPIA.detalles.forEach((value, index) => {
          delete COPIA.detalles[index].id
          delete COPIA.detalles[index].random
        })
      }

      this.formulario[0].secciones[this.indexSeccion].preguntas.splice(this.indexPregunta + 1, 0, COPIA)
      this.updateformulario()
    },
    limpiarMixtoDetalle(index) {
      if (this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta].detalles[index].mixto === '0') {
        this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta].detalles[index].mixto_detalles = ''
      }
      this.actualizarFormularioSegunTiempo()
    },
    cambiarPregunta(tipoPregunta) {
      this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta].tipo = tipoPregunta

      switch (tipoPregunta) {
        case 'respuesta_corta':
        case 'parrafo':
        case 'archivo':
        case 'hora':
        case 'fecha':
          this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta], 'detalles', [])
          this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta], 'compuesta', [])

          break

        case 'varias_opciones':
        case 'casillas':
        case 'desplegable':
        case 'mixto_varias_opciones':
        case 'mixto_casillas':
        case 'mixto_desplegable':

          this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta], 'compuesta', [])
          if (!this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta].detalles.length) {
            this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta], 'detalles', [])
            this.agregarOpcionFila(0)
          }
          break

        case 'compuesta':
          this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta], 'compuesta', [])
          this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta], 'detalles', [])
          this.formulario[0].secciones[this.indexSeccion].preguntas[this.indexPregunta].compuesta.push({ requerido: 0 })
          break

        default:
          break
      }

      this.updateformulario()
    },
    addPregunta(indexSeccion, indexPregunta) {
      this.formulario[0].secciones[indexSeccion].preguntas.splice(indexPregunta + 1, 0, {})

      // SI ES LA ULTIMA PREGUNTA  NO ENTRES AL IF
      this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[indexPregunta + 1], 'requerido', 0)
      this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[indexPregunta + 1], 'tipo', 'varias_opciones')
      this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[indexPregunta + 1], 'detalles', [])
      // iniciamos la pregunta en varias opciones
      this.formulario[0].secciones[this.indexSeccion].preguntas[indexPregunta + 1].detalles.push({ respuesta: '' })
      this.$set(this.formulario[0].secciones[this.indexSeccion].preguntas[indexPregunta + 1].detalles[0], 'respuesta', 'Opción 1')
      this.updateformulario()
    },
    addSeccion(indexSeccion, indexPregunta) {
      if (indexPregunta >= 0 && indexPregunta < (this.formulario[0].secciones[indexSeccion].preguntas.length - 1)) {
        const PREGUNTAS_SOBRANTES = this.formulario[0].secciones[indexSeccion].preguntas.filter((value, index) => index > indexPregunta)
        // elimina las prguntas despues del indice especificado
        this.formulario[0].secciones[indexSeccion].preguntas.splice(indexPregunta + 1)
        // adicionamos la seccion mas preguntas sobrantes
        this.formulario[0].secciones.splice(indexSeccion + 1, 0, { preguntas: PREGUNTAS_SOBRANTES, titulo: 'Sección sin titulo' })
      } else {
        this.formulario[0].secciones.splice(indexSeccion + 1, 0, { preguntas: [], titulo: 'Sección sin titulo' })
      }
      this.updateformulario()
    },
    async updateformulario() {
      store.state.newFormularioModule.showOverlay = true
      await store.dispatch('newFormularioModule/updateFormulario', this.formulario).then(res => {
        this.$set(this.formulario, 0, res.data)
      })
      store.state.newFormularioModule.showOverlay = false
      this.$toast({
        component: ToastificationContent,
        position: 'b-toaster-bottom-center',
        props: {
          title: '¡Formulario actualizado!',
          icon: 'CheckCircleIcon',
          variant: 'info',
        },
      })
    },

  },
}
</script>

<style scoped>
.list-group-item {
  transition: all 1s
}
.herramientas{
  background-color: white;
  border-radius: 5px;
}
.borderHover:hover {
  background-color: #EFFBF8;
}

</style>
