<template>
  <div
    :style="{height: trHeight}"
    class="repeater-form "
  >
    <div class="d-flex mx-3 mb-0 mt-2">

      <b-card
        class="classSeccion ml-3 mr-1 flex-grow-1 p-2 mb-1"
      ><div
         class="cabezeraSeccion"
       >
         Sección {{ indexSeccion +1 }} de {{ formulario[0].secciones.length }}
       </div>

        <div class="m-0 p-0">
          <b-row>
            <b-col
              cols="12"
              class="mb-1"
            >
              <b-form-input
                v-model="formulario[0].secciones[indexSeccion].titulo"
                size="lg"
                @update="actualizarFormularioSegunTiempo()"
              />
            </b-col>
            <b-col cols="12">
              <b-form-textarea
                v-model="formulario[0].secciones[indexSeccion].subtitulo"
                placeholder="Aqui puede añadir descripción a la sección ...."
                @update="actualizarFormularioSegunTiempo()"
              />
            </b-col>
          </b-row>
        </div>
        <br>
      </b-card>
      <div v-show="true">
        <div
          class="d-flex herramientas flex-column justify-content-between py-75"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.left.v-primary
            variant="flat-primary"
            class="btn-icon rounded-circle"
            title="Agregar nueva pregunta"
            @click="addPregunta(indexSeccion)"
          >
            <feather-icon
              size="26"
              icon="PlusCircleIcon"
              class="cursor-pointer"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.left.v-primary
            variant="flat-primary"
            class="btn-icon rounded-circle"
            title="Agregar nueva sección"
            @click="addSeccion(indexSeccion)"
          >
            <feather-icon
              size="26"
              icon="ServerIcon"
              class="cursor-pointer"
            />
          </b-button>

          <!-- <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.left.v-primary
            variant="flat-primary"
            class="btn-icon rounded-circle"
            title=""
          >
            <feather-icon
              size="26"
              icon="CopyIcon"
              class="cursor-pointer"
            />
          </b-button> -->
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            v-b-tooltip.hover.left.v-primary
            variant="flat-primary"
            class="btn-icon rounded-circle"
            title="Eliminar sección"
            @click="removeSeccion(indexSeccion)"
          >
            <feather-icon
              size="26"
              icon="Trash2Icon"
              class="cursor-pointer"
            />
          </b-button>
        </div>
      </div>
    </div>
    <b-row
      v-for="(item, indexPregunta) in formulario[0].secciones[indexSeccion].preguntas"
      :key="indexSeccion+''+indexPregunta"
    >
      <b-col cols="12">
        <new-pregunta
          :index-seccion="indexSeccion"
          :index-pregunta="indexPregunta"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>import {
  BCard, BRow, BCol, BFormInput, BFormTextarea, VBTooltip, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NewPregunta from './newPregunta.vue'

export default {
  components: {
    BFormTextarea,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormInput,
    // extern
    NewPregunta,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,

  },
  mixins: [heightTransition],
  props: {
    indexSeccion: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      preguntas: [],
      timeout: null,
    }
  },
  computed: {
    ...mapState('newFormularioModule', ['formulario', 'showOverlay']),
  },

  methods: {
    actualizarFormularioSegunTiempo() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.updateformulario()
      }, 1800)
    },
    addPregunta(indexSeccion) {
      this.formulario[0].secciones[indexSeccion].preguntas.splice(0, 0, {
        tipo: 'varias_opciones',
        detalles: [
          { respuesta: 'Opción 1' },
        ],
        requerido: 0,
      })

      this.updateformulario()
    },
    addSeccion(indexSeccion) {
      this.formulario[0].secciones.splice(indexSeccion, 0, { preguntas: [], titulo: 'Sección sin titulo' })
      this.updateformulario()
    },
    removeSeccion(indexSeccion) {
      this.$swal({
        title: '¿Esta seguro de eliminar la sección?',
        text: 'Al eliminar la sección se eliminaran las preguntas que contiene.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, ¡Eliminar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.formulario[0].secciones.splice(indexSeccion, 1)
          this.updateformulario()
        }
      })
    },
    async updateformulario() {
      store.state.newFormularioModule.showOverlay = true
      await store.dispatch('newFormularioModule/updateFormulario', this.formulario).then(res => {
        this.$set(this.formulario, 0, res.data)
      })
      store.state.newFormularioModule.showOverlay = false
      this.$toast({
        component: ToastificationContent,
        position: 'b-toaster-bottom-center',
        props: {
          title: '¡Formulario actualizado!',
          icon: 'CheckCircleIcon',
          variant: 'info',
        },
      })
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.classSeccion {
border-top: #048975 10px solid;
}
.herramientas{
  background-color: white;
  border-radius: 5px;
}
.cabezeraSeccion{
  background-color: #048975;
  left: -42px;
  position: relative;
  top: -52px;
  width: 150px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 7px 20px;
  color: white;
  font-size: 12px;
}
</style>
