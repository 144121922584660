<template>
  <div>
    <div class="ml-4 mb-1">
      <b-button
        variant="outline-primary"
        size="sm"
        class="px-3"
        @click="$router.push({name: nombreruta})"
      >
        <feather-icon icon="ArrowLeftIcon" />
        Volver atras</b-button>
    </div>
    <div class="bg-light-info p-1 mx-4 mb-1 rounded">
      <h4 class="text-danger">
        <strong> ¡EN CASO EL FORMULARIO CONTENGA RESPUESTAS! </strong>
      </h4>
      <strong class="text-danger">Si la pregunta es modificada en el "tipo de pregunta" se eliminaran las respuestas que contiene dicha pregunta.</strong>
    </div>
    <newFormulario
      :id-formulario="idFormulario"
    />
  </div>
</template>

<script>

import { BButton } from 'bootstrap-vue'
import newFormulario from '@/views/administrador/gestion-formulario/nuevo-formulario/edit-formulario/newFormulario.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  components: {
    newFormulario,
    FeatherIcon,
    BButton,
  },

  data() {
    return {
      idFormulario: parseInt(this.$route.params.idFormulario, 10),
      nombreruta: '',
    }
  },
  created() {
    this.$http.get(`/recursos/formulario/${this.$route.params.idFormulario}`)
      .then(res => {
        switch (res.data.codigo) {
          case 'SATISFACCION-COMEDOR':
            this.nombreruta = 'satisfaccion-estudiante-social'
            break
          case 'SATISFACCION-SALUD':
            this.nombreruta = 'satisfaccion-estudiante-salud'
            break
          case 'SATISFACCION-OCADE':
            this.nombreruta = 'satisfaccion-estudiante-ocade'
            break
          case 'HOSTIGAMIENTO-ENCUESTA':
            this.nombreruta = 'gestion-formulario-encuesta'
            break

          default:
            this.nombreruta = 'dashboard-home'
            break
        }
      })
  },

}
</script>
